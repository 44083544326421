@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";
@import "uikit/src/scss/uikit-theme.scss";

.AppContainer {
  min-height: 100vh;

  transition: background-color ease-in-out 0.3s;
  overflow-x: hidden;
}
.AppFooter {
  position: fixed;
  width: 8em;
  bottom: 0;
  right: 0;
  margin-bottom: 6px;

  display: flex;
  justify-content: center;

  p {
    margin: 0;

    pointer-events: none;
    user-select: none;
  }
}
